@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0;
  box-sizing: border-box;
}

a {
  color: currentColor;
  text-decoration: none;
}

li {
  list-style-type: none;
}

.light-mode {
  --font-color: #000000;
  --font-color-reverse: #ffffff;
  --body-color: #ffffff;
  --body-color-reverse: #000000;
  --primary-color: #007bff;
  --secondary-color: #28a745;
  --tertiary-color: #005f8e;
  --quaternary-color: #333333;
  --quinary-color: #6c757d;
  --senary-color: #d6d6d6;
}

.dark-mode {
  --font-color: #ffffff;
  --font-color-reverse: #000000;
  --background-color: #000000;
  --background-color-reverse: #ffffff;
  --primary-color: #007bff;
  --secondary-color: #28a745;
  --tertiary-color: #005f8e;
  --quaternary-color: #333333;
  --quinary-color: #6c757d;
  --senary-color: #d6d6d6;
}
